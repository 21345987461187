import React from 'react';
import PropTypes from 'prop-types';

const DownloadIcon = ({ width, height, color }) => (
<svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 18 19'>
    <path fill='#4A4A4A' fillRule='evenodd' d='M15.89 15.438c0 .187-.07.351-.21.492a.675.675 0 0 1-.493.21.675.675 0 0 1-.492-.21.675.675 0 0 1-.21-.492c0-.188.07-.352.21-.493.14-.14.305-.21.492-.21.188 0 .352.07.493.21.14.14.21.305.21.493zm-2.953-.704c.188 0 .352.07.493.211.14.14.21.305.21.493 0 .187-.07.351-.21.492a.675.675 0 0 1-.493.21.675.675 0 0 1-.492-.21.675.675 0 0 1-.21-.492c0-.188.07-.352.21-.493.14-.14.305-.21.492-.21zM18 13.047v3.656c0 .422-.152.785-.457 1.09a1.488 1.488 0 0 1-1.09.457H1.547c-.422 0-.785-.152-1.09-.457A1.488 1.488 0 0 1 0 16.703v-3.656c0-.422.152-.785.457-1.09a1.488 1.488 0 0 1 1.09-.457h3.48L3.06 9.531c-.282-.304-.416-.644-.405-1.02.012-.374.147-.702.405-.984.257-.28.586-.422.984-.422h1.863V1.656c0-.398.135-.732.405-1.002A1.36 1.36 0 0 1 7.312.25h3.375c.399 0 .733.135 1.002.404.27.27.405.604.405 1.002v5.45h1.863c.398 0 .727.14.984.421.258.282.393.61.405.985.011.375-.123.715-.405 1.02L12.973 11.5h3.48c.422 0 .785.152 1.09.457.305.305.457.668.457 1.09zm-9.211.633c.07.047.14.07.211.07.07 0 .14-.023.21-.07l4.923-4.957c.094-.094.117-.2.07-.317-.047-.117-.129-.176-.246-.176h-2.988V1.656c0-.07-.03-.134-.088-.193a.269.269 0 0 0-.194-.088H7.312c-.07 0-.134.03-.193.088a.269.269 0 0 0-.088.193V8.23H4.043c-.117 0-.2.06-.246.176-.047.117-.024.223.07.317L8.79 13.68zm8.086-.633a.407.407 0 0 0-.123-.299.407.407 0 0 0-.299-.123h-4.605l-1.864 1.828a1.277 1.277 0 0 1-.984.422c-.398 0-.727-.14-.984-.422l-1.864-1.828H1.547a.407.407 0 0 0-.299.123.407.407 0 0 0-.123.299v3.656c0 .117.041.217.123.299a.407.407 0 0 0 .299.123h14.906a.407.407 0 0 0 .299-.123.407.407 0 0 0 .123-.299v-3.656z'
    />
</svg>
);

DownloadIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};

DownloadIcon.defaultProps = {
  width: '18',
  height: '19',
  color: '#fff'
};

export default DownloadIcon;