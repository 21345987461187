import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

const ValidateAccess = ({ environment, pageType, currentPath, isSubscriber }) => {
  const [dynamicAdUnitName, setDynamicAdUnitName] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [showAd, setShowAd] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const mobile = width <= 768;
      let renderAd = window.location.pathname.substring(1).split('/')[0] === 'bq-brand-studio' ? false : true;
      setIsMobile(mobile);
      setShowAd(renderAd);
      localStorage.setItem('isMobile', JSON.stringify(mobile));
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && showAd) {
      const deviceType = window.innerWidth <= 768 ? 'WAP' : 'Desktop';
      let category = window.location.pathname.substring(1).split('/')[0].replace(/[-\s]/g, '');
      if (!category) {
        category = 'homepage';
      }
      const formattedCategory = pageType === 'home-page' ? 'homepage' : category.replace(/[-\s]/g, '');
      const formattedPageType = pageType === 'home-page' ? 'Home' : (pageType === 'story-page' ? 'Detail' : 'Listing');
      const InterstitialAdUnitPath320x480 = `/1068322/Profit_Eng/${deviceType}/${formattedPageType}/${formattedCategory}/Interstitial_320x480`;
      setDynamicAdUnitName(InterstitialAdUnitPath320x480);
    }
  }, [pageType]);


  useEffect(() => {
    const googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(() => {
      if (isMobile && dynamicAdUnitName && isSubscriber !== null && !isSubscriber) {
        var interstitialSlot320x480 = googletag.defineOutOfPageSlot(
          dynamicAdUnitName,
          googletag.enums.OutOfPageFormat.INTERSTITIAL
        );
        if (interstitialSlot320x480) {
          interstitialSlot320x480.addService(googletag.pubads());
          googletag.enableServices();
          googletag.display(interstitialSlot320x480.getSlotElementId());
        }
      }

      googletag.pubads().addEventListener('slotRenderEnded', function (event) {
        if (event.slot === interstitialSlot320x480) {
          console.log('Interstitial_320x480 is loaded.');
        } else {
          console.log('Loaded slot does not match target slot.');
        }
      });
    });
  }, [isMobile, pageType, isSubscriber]);

  return null;
};

ValidateAccess.propTypes = {
  pageType: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
  environment: PropTypes.string,
};

const DisplayAds = ({ pageType, currentPath, isSubscriber }) => (
  <ValidateAccess pageType={pageType} currentPath={currentPath} isSubscriber={isSubscriber} />
);

DisplayAds.propTypes = {
  pageType: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  isSubscriber: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const publisherConfig = state.qt.config.publisher;
  return {
    currentPath: state.qt.currentPath || '',
    environment: get(publisherConfig, ['ads'].environment, 'production'),
    pageType: get(state, ['qt', 'pageType'], 'home'),
    isSubscriber: get(state, ['userData', 'isSubscriber'], 'false'),
  };
};

export default connect(mapStateToProps, {})(DisplayAds);
