import React, { useEffect, useState } from 'react';
import { array } from 'prop-types';
import './global-header.m.css';

const defaultData = [
  {
    Title: 'BUSINESS',
    Link: '/',
  },
  {
    Title: 'बिजनेस',
    Link: 'https://hindi.ndtvprofit.com',
  },
];

const GlobalHeader = ({ features }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          features.ndtv_global_nav.url || 'https://edata.ndtv.com/feeds/hp/Globalnavhp23_23.json',
          {
            mode: 'cors',
          }
        );
        const jsonData = await response.json();
        const newList = jsonData
          .filter((list) => list.Title.toLowerCase() !== 'profit')
          ?.map((item) => {
            if (item.Title === 'TECH') {
              return [item, ...defaultData];
            }
            return item;
          })
          .flat();
        setData(newList);
      } catch (error) {
        console.error('Error during fetch:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div styleName="menu-wrapper" className="desktop-only">
      <div styleName="main-menu">
        {data?.length > 0
          ? data.map((item) => {
              return (
                <a
                  href={
                    `${item.Link}?utm_source=profit&utm_medium=referral&utm_campaign=globnav` || '/'
                  }
                  styleName={item.Title === 'BUSINESS' ? 'select-list' : 'menu-item'}
                  target={item.Title === 'BUSINESS' ? '_self' : '_blank'}
                  key={item.id}
                >
                  {item.Title}
                </a>
              );
            })
          : new Array(15).fill(null).map((element, i) => {
              return <span styleName="menu-item-skeleton" key={i + element} />;
            })}
      </div>
    </div>
  );
};

GlobalHeader.propTypes = {
  menu: array.isRequired,
};

GlobalHeader.defaultProps = {
  menu: [],
};

export default GlobalHeader;
