/* eslint-disable prefer-const */
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { BQLink } from '../../../atoms/bqlink';
import MobileSearchBar from '../../../atoms/mobile-search-bar';
import SvgIcon from '../../../atoms/svg-icon';
import MemberMenu from '../../../molecules/member-menu/member-menu.js';
import './header-top.m.css';
import { PATH } from '../../../../constants.js';

const HeaderTop = (props) => {
  const isSubscriber = useSelector((state) => get(state, ['userData', 'isSubscriber'], null));
  const client_app_version = useSelector((state) =>
    get(state, ['qt', 'config', 'publisher', 'client_app_version'], null)
  );
  const independence = props?.config.features.independence_day;
  const clearAllCaches = async () => {
    const allCaches = await caches.keys();
    for (const cache of allCaches) {
      caches.delete(cache);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && client_app_version) {
      const local_version = localStorage.getItem('client_app_version');
      if (local_version == null) {
        localStorage.setItem('client_app_version', client_app_version);
      } else {
        if (local_version !== client_app_version) {
          sessionStorage.clear();
          localStorage.clear();
          indexedDB.databases().then((dbs) => {
            dbs.forEach((db) => indexedDB.deleteDatabase(db.name));
          });
          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
              for (let registration of registrations) {
                registration.unregister();
              }
            });
          }
          clearAllCaches();
          window.location.reload(true);
          localStorage.setItem('client_app_version', client_app_version);
        }
      }
    }
  }, []);

  return (
    <div styleName="header-top-container">
      <div styleName="header-top" className="container">
        <div styleName="mobile-search-with-language-toggle">
          <MobileSearchBar />
        </div>
        <div styleName="language-toggle" className="desktop-only">
          <div styleName="toggle-btn-container">
            <button styleName="toggle-btn toggle-selected">English</button>
            <BQLink
              href="https://hindi.ndtvprofit.com?utm_source=profit&utm_medium=referral&utm_campaign=language"
              target="_blank"
              rel="noreferrer"
            >
              <button styleName="toggle-btn">Hindi</button>
            </BQLink>
          </div>
        </div>
        <div>
          <BQLink href="/?src=header" className="mobile-only mob-view">
            <SvgIcon
              type="ndtv_group_logo.webp"
              width="158px"
              height="27px"
              source="image"
              isSvg={false}
            />
          </BQLink>
          <BQLink href="/?src=header" className="desktop-only">
            <SvgIcon
              type="ndtv_group_logo.webp"
              width="173px"
              height="29px"
              source="image"
              isSvg={false}
            />
          </BQLink>
        </div>
        <div styleName="right-ctx">
          {isSubscriber === null ? (
            <div styleName="btn-loading-grey" className="desktop-only" />
          ) : (
            !isSubscriber && (
              <>
                <div styleName="subscribe-button-wrapper" className="desktop-only">
                  {independence?.active ? (
                    <BQLink href={`${PATH.SUBSCRIPTION_PAGE}?src=subs_hdr`}>
                      <img src={independence.subscription_btn.shadow} styleName="btn-shadow" />
                      <div styleName="button btn-theme">Subscribe</div>
                    </BQLink>
                  ) : (
                    <BQLink href={`${PATH.SUBSCRIPTION_PAGE}?src=subs_hdr`} styleName="button">
                      Subscribe
                    </BQLink>
                  )}
                </div>
              </>
            )
          )}

          <div styleName="user-with-subscribe-btn">
            <MemberMenu {...props} />
          </div>
          <div styleName="live-tv-icon" className="mobile-only">
            <BQLink href="/live-tv?src=topnav_livetv" styleName="live-icon" title="Live TV">
              <SvgIcon
                type="live_tv"
                iconStyle={{ width: '32px', height: '27px' }}
                source="image"
              />
            </BQLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
