/* eslint-disable max-len */
import './mobile-bottom-sticky.m.css';

import React, { useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';

import Button from '../../atoms/button';
import CheckMark from '../../atoms/logo/check-mark';
import CrossBtn from '../../atoms/logo/cross-btn';
import DfpAd from '../../atoms/dfp-ad';
import { PATH } from './../../../constants';
import SvgIcon from '../../atoms/svg-icon';
import { connect, useSelector } from 'react-redux';
import { get } from 'lodash';
import { getUserDetails } from '../../../helpers/utils';
import { isMobile } from 'react-device-detect';
import MoengageHelper from '../../../helpers/moengage-helper';
import { logOut, loginCompleted } from '../../../helpers/datalayer-utils';

const MobileBottomSticky = ({ onHide, pageType, isAmpPage }) => {
  const userData = useSelector((state) => get(state, ['userData']));
  const { isLoggedIn, isSubscriber } = userData || {};
  const [showMinimalExperience, setShowMinimalExperience] = useState(false);
  const minimalAdExperienceClass = showMinimalExperience ? 'increase-z-index' : '';
  const formattedPageType = pageType != 'home-page' ? 'section-page' : 'home-page';
  const handleCrossBtn = (e) => {
    setShowMinimalExperience(!showMinimalExperience);
    onHide(showMinimalExperience);
    document.body.style.overflowY = showMinimalExperience ? 'inherit' : 'hidden';
  };


  useEffect(() => {
    const fetchUserType = async () => {
      if (window.dataLayer && userData) {
        const baseDataLayerFields = {
          event: 'user_property',
          email: userData?.userProfile?.email || '',
          user_id: userData?.userProfile?.userId,
          full_name: userData?.userProfile?.fullName,
          mobile: userData?.userSubscription?.active?.metadata?.mobile_number || '',
          login_status: 'logged-in',
        };

        if (userData?.isLoggedIn === true) {
          localStorage.setItem('local_login_status', "logged-in");
          if (userData?.isSubscriber === true && userData?.userSubscription !== null) {
            const planDetails = getSubscriptionDetails(userData);
            const subscriberDataLayerFields = {
              ...baseDataLayerFields,
              user_type: 'subscriber',
              ...planDetails
            };
            window.dataLayer.push(subscriberDataLayerFields);
            localStorage.setItem('local_user_property', JSON.stringify(subscriberDataLayerFields));
          } else if (userData?.isSubscriber === false) {
            const nonSubscriberDataLayerFields = {
              ...baseDataLayerFields,
              user_type: 'non-subscriber'
            };
            window.dataLayer.push(nonSubscriberDataLayerFields);
            localStorage.setItem('local_user_property', JSON.stringify(nonSubscriberDataLayerFields));
          }
          MoengageHelper.userLogin(userData);
        } else if (userData?.isLoggedIn === false) {
          const loggedOutDataLayerFields = {
            event: 'user_property',
            login_status: 'logged-out',
            user_type: 'anonymous-user',
          };
          window.dataLayer.push(loggedOutDataLayerFields);
          localStorage.setItem('local_user_property', JSON.stringify(loggedOutDataLayerFields));
          localStorage.setItem('local_login_status', 'logged-out');

        }
      }
    };

    if (userData !== null) {
      fetchUserType();
    }
  }, [userData?.isSubscriber, userData?.userSubscription]);


  function getSubscriptionDetails(userData) {
    const planType = userData?.userSubscription?.active?.subscription_type || 'NA';
    const planName = userData?.userSubscription?.active?.plan_name || 'NA';
    const daysPassed = userData?.userSubscription?.active?.duration_length;
    const subscriptionStartDate = userData?.userSubscription?.active?.start_timestamp;
    const subscriptionRenewDate = userData?.userSubscription?.active?.end_timestamp;
    const planId = userData?.userSubscription?.active?.subscription_plan_id;
    const planFinalAmount = userData?.userSubscription?.active?.payment_amount;

    return {
      plan_type: planType,
      plan_name: planName,
      plan_duration: daysPassed,
      plan_start_date: subscriptionStartDate,
      plan_end_date: subscriptionRenewDate,
      plan_id: planId,
      plan_final_amount: planFinalAmount,
      payment_currency: 'INR',
    };
  }

  // eslint-disable-next-line camelcase
  const page_property = async () => {
    try {
      const eventData = {
        event: 'page_property',
        page_template: isAmpPage ? 'amp-story' : formattedPageType,
        category: formattedPageType === 'home-page' ? 'homepage' : window.location.pathname.substring(1).split('/')[0].replace(/[-\s]/g, '')
      };
      if (isLoggedIn === true) {
        eventData.user_type = isSubscriber ? 'subscriber' : 'non-subscriber';
        eventData.login_status = "logged-in";
      } else if (isLoggedIn === false) {
        eventData.user_type = 'anonymous-user';
        eventData.login_status = "logged-out";
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(eventData);
    } catch (error) {
      console.error('Error in page_property:', error);
    }
  };

  useEffect(() => {
    if (pageType !== null && formattedPageType !== null && pageType !== 'story-page' && window.dataLayer && isLoggedIn !== null && isSubscriber !== null) {
      page_property();
    }
  }, [isLoggedIn, isSubscriber, pageType]);

  const [showAd, setShowAd] = useState(true);
  useEffect(() => {
    if (window.location.href.includes('/bq-brand-studio/')) {
      setShowAd(false);
    }
  }, []);
  const currentPath = useSelector((state) => get(state, ['qt', 'currentPath']));
  return (
    <>
      {isMobile && showAd && userData?.isSubscriber === false && !currentPath.includes('/subscription') && (
        <div styleName={`sticky-wrapper ${minimalAdExperienceClass}`}>
          <div styleName={!showMinimalExperience ? 'sticky-responsive-ad-wrapper' : 'hide'}>
            <div styleName="sticky-responsive-ad">
              <DfpAd
                adtype="MobileSticky"
                adUnit="Sticky_320x75"
                sizes={[[320, 75], [320, 50]]}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
MobileBottomSticky.propTypes = {
  onHide: func,
  pageType: string,
  isAmpPage: bool,
};
const mapStateToProps = (state) => {
  return {
    pageType: get(state, ['qt', 'pageType'], null),
  };
};

export default connect(mapStateToProps, () => ({}))(MobileBottomSticky);
