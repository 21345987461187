import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import UserRoundIcon from '../../atoms/icons/user-round-icon';
import DropdownArrowIcon from '../../atoms/icons/dropdown-arrow-icon';
import './member-menu.module.css';
import { connect } from 'react-redux';

const LoggedInView = ({ toggleDropdownMenu, userProfile }) => {
  const [member, setMember] = useState(null);
  const [nameInitials, setNameInitials] = useState(null);
  const [name, setName] = useState(null)
  useEffect(() => {
    if (userProfile) {
      if(userProfile.fullName) {
        const member = {
          firstName: userProfile?.fullName?.split(' ')[0],
          lastName: userProfile?.fullName?.split(' ')[userProfile.fullName.split(' ').length - 1],
        };
        setMember(member);
        const name_initials = member?.lastName
          ? member?.firstName.charAt(0) + member?.lastName.charAt(0)
          : member?.firstName.charAt(0) + member?.firstName.charAt(1);
        setNameInitials(name_initials);
      } else if(userProfile.email) {
        setNameInitials(userProfile.email.charAt(0) + userProfile.email.charAt(1))
        setName((userProfile.email.charAt(0) + userProfile.email.charAt(1)).toUpperCase())
      }
    }
  }, [userProfile]);

  return (
    <>
      {userProfile && userProfile.userId && (
        <button styleName="user-btn" onClick={toggleDropdownMenu}>
          <UserRoundIcon color={'regent-gray'} fontSize={'15px'} />
          <div className="desktop-only">{member?.firstName || name || "Reader"}</div>
          <div className="mobile-only">{nameInitials || name || "Reader"}</div>
          <DropdownArrowIcon color="var(--second-color)" fontSize="3px" />
        </button>
      )}
    </>
  );
};

LoggedInView.propTypes = {
  toggleDropdownMenu: PropTypes.func.isRequired,
  hideSubscribe: PropTypes.bool,
  hideUserName: PropTypes.bool,
  trackSubscribeClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userProfile: get(state, ['userData', 'userProfile'], null),
});

export default connect(mapStateToProps, {})(LoggedInView);
