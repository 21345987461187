import React from 'react';
import MaterialIconWrapper from './material-icon-wrapper';
import {
  mdiConnection,
  mdiPlus,
  mdiAccountGroup,
  mdiAccountCircle,
  mdiContentCopy,
  mdiChevronDown,
  mdiMenuDown,
  mdiPlay,
  mdiPlayCircle,
  mdiChevronRight,
  mdiDice5,
  mdiPlayOutline,
  mdiAlert,
  mdiShareVariantOutline,
  mdiBlur,
  mdiWalletGiftcard,
  mdiPower,
  mdiMemory,
  mdiMenu,
  mdiHistory,
  mdiBinoculars,
  mdiForum,
  mdiCreditCard,
  mdiInformationOutline,
  mdiCheck,
  mdiAttachment,
  mdiPause,
  mdiBroadcast,
  mdiLightbulbOn10,
  mdiEyeOff,
  mdiFullscreen,
  mdiClose,
  mdiPlayCircleOutline,
  mdiVirus,
  mdiMagnify,
  mdiClockOutline,
  mdiWeb,
  mdiEye,
  mdiArrowLeft,
  mdiInstagram,
  mdiPencil,
  mdiTrendingUp,
  mdiYoutube,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiTrendingDown,
  mdiArrowRight,
  mdiBookmarkCheckOutline,
  mdiCheckDecagram,
  mdiVideoPlus,
  mdiReceiptText,
  mdiTimer,
  mdiAlertOctagon,
  mdiCalendarMonth,
  mdiBookmark,
  mdiBookmarkOutline,
  mdiTwitter,
  mdiLinkedin,
  mdiWhatsapp,
  mdiReddit,
  mdiEmail,
  mdiBell,
  mdiUmbrellaBeach,
  mdiFlash,
  mdiMonitor,
  mdiFacebook,
  mdiBriefcaseVariant,
  mdiGavel,
  mdiCurrencyInr,
  mdiFilmstrip,
  mdiFormatQuoteClose,
  mdiChartTimelineVariant,
  mdiUpload,
  mdiChevronTripleLeft,
  mdiSwapHorizontal,
  mdiCancel,
  mdiCogOutline,
  mdiCog,
  mdiChatOutline,
  mdiMessageOutline,
  mdiArrowDown,
  mdiLockOutline,
  mdiLock,
  mdiCastConnected,
  mdiSale,
  mdiBookVariant,
  mdiFileDocument,
  mdiMinusCircleOff,
  mdiStar,
  mdiBookOpen,
  mdiSchool,
  mdiMoonWaningCrescent,
  mdiRadioboxMarked,
  mdiDelete,
  mdiFoodDrumstick,
  mdiWhiteBalanceSunny,
  mdiWeatherSunny,
  mdiDownload,
  mdiHelpCircleOutline,
  mdiAccountBadge,
  mdiCookie,
  mdiWifiOff,
  mdiStarCircleOutline,
  mdiChartBoxOutline,
  mdiTicketConfirmationOutline,
  mdiLibraryOutline,
  mdiBank,
  mdiLayersOutline,
  mdiCurrencyBtc,
  mdiFinance,
  mdiTextBox,
  mdiPodiumGold,
  mdiVoteOutline,
  mdiSafe,
  mdiCalculator,
  mdiHandshakeOutline,
  mdiGold,
  mdiMicrophoneVariant,
  mdiCommentQuoteOutline,
  mdiSchoolOutline,
  mdiWindTurbine,
  mdiUmbrellaBeachOutline,
  mdiViewCarousel,
  mdiFileSign,
  mdiFire,
  mdiOpenInNew,
  mdiListBoxOutline,
  mdiHomeAnalytics,
  mdiCrystalBall,
  mdiChartPieOutline,
  mdiBookSearchOutline,
  mdiOrderAlphabeticalAscending,
  mdiCalendarTodayOutline,
  mdiFileChartOutline,
  mdiStateMachine,
  mdiFileCompare,
  mdiDatabaseOutline,
  mdiAbacus,
  mdiChartTimelineVariantShimmer,
  mdiBlurLinear,
  mdiVideoOutline,
  mdiVideoCheckOutline,
  mdiPercentBoxOutline,
  mdiCarBrakeHold,
  mdiBullhornVariantOutline,
  mdiChartTimeline,
  mdiMessageAlertOutline,
  mdiTelescope,
  mdiChevronLeft,
  mdiAccountEditOutline,
  mdiCricket,
  mdiChevronUp,
  mdiChartBubble,
  mdiTelevisionClassic,
  mdiLifebuoy,
  mdiHeart,
  mdiCellphone,
  mdiCellphoneArrowDownVariant,
  mdiPiggyBank,
  mdiStarFourPoints,
  mdiVote
} from '@mdi/js';
export const NotificationsIcon = props => <MaterialIconWrapper iconName={mdiConnection} {...props} />;
export const PlusIcon = props => <MaterialIconWrapper iconName={mdiPlus} {...props} />;
export const AccountGroupIcon = props => <MaterialIconWrapper iconName={mdiAccountGroup} {...props} />;
export const AccountCircleIcon = props => <MaterialIconWrapper iconName={mdiAccountCircle} {...props} />;
export const ContentCopyIcon = props => <MaterialIconWrapper iconName={mdiContentCopy} {...props} />;
export const ChevronDownIcon = props => <MaterialIconWrapper iconName={mdiChevronDown} {...props} />;
export const MenuDownIcon = props => <MaterialIconWrapper iconName={mdiMenuDown} {...props} />;
export const PlayIcon = props => <MaterialIconWrapper iconName={mdiPlay} {...props} />;
export const PlayCircleIcon = props => <MaterialIconWrapper iconName={mdiPlayCircle} {...props} />;
export const ChevronRightIcon = props => <MaterialIconWrapper iconName={mdiChevronRight} {...props} />;
export const DiceIcon = props => <MaterialIconWrapper iconName={mdiDice5} {...props} />;
export const PlayOutlineIcon = props => <MaterialIconWrapper iconName={mdiPlayOutline} {...props} />;
export const AlertIcon = props => <MaterialIconWrapper iconName={mdiAlert} {...props} />;
export const ShareVariantOutlineIcon = props => <MaterialIconWrapper iconName={mdiShareVariantOutline} {...props} />;
export const BlurIcon = props => <MaterialIconWrapper iconName={mdiBlur} {...props} />;
export const WalletGiftCardIcon = props => <MaterialIconWrapper iconName={mdiWalletGiftcard} {...props} />;
export const PowerIcon = props => <MaterialIconWrapper iconName={mdiPower} {...props} />;
export const MemoryIcon = props => <MaterialIconWrapper iconName={mdiMemory} {...props} />;
export const MenuIcon = props => <MaterialIconWrapper iconName={mdiMenu} {...props} />;
export const HistoryIcon = props => <MaterialIconWrapper iconName={mdiHistory} {...props} />;
export const BinocularsIcon = props => <MaterialIconWrapper iconName={mdiBinoculars} {...props} />;
export const ForumIcon = props => <MaterialIconWrapper iconName={mdiForum} {...props} />;
export const CreditCardIcon = props => <MaterialIconWrapper iconName={mdiCreditCard} {...props} />;
export const InformationOutlineIcon = props => <MaterialIconWrapper iconName={mdiInformationOutline} {...props} />;
export const CheckIcon = props => <MaterialIconWrapper iconName={mdiCheck} {...props} />;
export const AttachementIcon = props => <MaterialIconWrapper iconName={mdiAttachment} {...props} />;
export const PauseIcon = props => <MaterialIconWrapper iconName={mdiPause} {...props} />;
export const BroadcastIcon = props => <MaterialIconWrapper iconName={mdiBroadcast} {...props} />;
export const LightBulbOnIcon = props => <MaterialIconWrapper iconName={mdiLightbulbOn10} {...props} />;
export const EyeOffIcon = props => <MaterialIconWrapper iconName={mdiEyeOff} {...props} />;
export const FullScreenIcon = props => <MaterialIconWrapper iconName={mdiFullscreen} {...props} />;
export const CloseIcon = props => <MaterialIconWrapper iconName={mdiClose} {...props} />;
export const PlayCircleOutlineIcon = props => <MaterialIconWrapper iconName={mdiPlayCircleOutline} {...props} />;
export const VirusIcon = props => <MaterialIconWrapper iconName={mdiVirus} {...props} />;
export const MaginifyIcon = props => <MaterialIconWrapper iconName={mdiMagnify} {...props} />;
export const ClockOutlineIcon = props => <MaterialIconWrapper iconName={mdiClockOutline} {...props} />;
export const WebIcon = props => <MaterialIconWrapper iconName={mdiWeb} {...props} />;
export const EyeIcon = props => <MaterialIconWrapper iconName={mdiEye} {...props} />;
export const ArrowLeftIcon = props => <MaterialIconWrapper iconName={mdiArrowLeft} {...props} />;
export const InstagramIcon = props => <MaterialIconWrapper iconName={mdiInstagram} {...props} />;
export const PencilIcon = props => <MaterialIconWrapper iconName={mdiPencil} {...props} />;
export const TrendingUpIcon = props => <MaterialIconWrapper iconName={mdiTrendingUp} {...props} />;
export const YouTubeIcon = props => <MaterialIconWrapper iconName={mdiYoutube} {...props} />;
export const CheckboxBlankIcon = props => <MaterialIconWrapper iconName={mdiCheckboxBlankOutline} {...props} />;
export const CheckboxMarkedIcon = props => <MaterialIconWrapper iconName={mdiCheckboxMarked} {...props} />;
export const TrendingDownIcon = props => <MaterialIconWrapper iconName={mdiTrendingDown} {...props} />;
export const ArrowRightIcon = props => <MaterialIconWrapper iconName={mdiArrowRight} {...props} />;
export const BookMarkCheckOutOutlineIcon = props => <MaterialIconWrapper iconName={mdiBookmarkCheckOutline} {...props} />;
export const CheckDecagramIcon = props => <MaterialIconWrapper iconName={mdiCheckDecagram} {...props} />;
export const VideoPlusIcon = props => <MaterialIconWrapper iconName={mdiVideoPlus} {...props} />;
export const ReceiptTextIcon = props => <MaterialIconWrapper iconName={mdiReceiptText} {...props} />;
export const TimerIcon = props => <MaterialIconWrapper iconName={mdiTimer} {...props} />;
export const AlertOctagonIcon = props => <MaterialIconWrapper iconName={mdiAlertOctagon} {...props} />;
export const CalendarMonthIcon = props => <MaterialIconWrapper iconName={mdiCalendarMonth} {...props} />;
export const BookmarkIcon = props => <MaterialIconWrapper iconName={mdiBookmark} {...props} />;
export const BookmarkOutlineIcon = props => <MaterialIconWrapper iconName={mdiBookmarkOutline} {...props} />;
export const TwitterIcon = props => <MaterialIconWrapper iconName={mdiTwitter} {...props} />;
export const LinkedInIcon = props => <MaterialIconWrapper iconName={mdiLinkedin} {...props} />;
export const WhatsappIcon = props => <MaterialIconWrapper iconName={mdiWhatsapp} {...props} />;
export const RedditIcon = props => <MaterialIconWrapper iconName={mdiReddit} {...props} />;
export const EmailIcon = props => <MaterialIconWrapper iconName={mdiEmail} {...props} />;
export const BellIcon = props => <MaterialIconWrapper iconName={mdiBell} {...props} />;
export const UmbrellaBeachIcon = props => <MaterialIconWrapper iconName={mdiUmbrellaBeach} {...props} />;
export const FlashIcon = props => <MaterialIconWrapper iconName={mdiFlash} {...props} />;
export const MonitorIcon = props => <MaterialIconWrapper iconName={mdiMonitor} {...props} />;
export const FacebookIcon = props => <MaterialIconWrapper iconName={mdiFacebook} {...props} />;
export const BriefCaseVariantIcon = props => <MaterialIconWrapper iconName={mdiBriefcaseVariant} {...props} />;
export const GavelIcon = props => <MaterialIconWrapper iconName={mdiGavel} {...props} />;
export const CurrencyInrIcon = props => <MaterialIconWrapper iconName={mdiCurrencyInr} {...props} />;
export const FilmStripIcon = props => <MaterialIconWrapper iconName={mdiFilmstrip} {...props} />;
export const FormatQuoteCloseIcon = props => <MaterialIconWrapper iconName={mdiFormatQuoteClose} {...props} />;
export const ChartTimeVariantIcon = props => <MaterialIconWrapper iconName={mdiChartTimelineVariant} {...props} />;
export const UploadIcon = props => <MaterialIconWrapper iconName={mdiUpload} {...props} />;
export const ChevronTripLeftIcon = props => <MaterialIconWrapper iconName={mdiChevronTripleLeft} {...props} />;
export const SwapHorizontalIcon = props => <MaterialIconWrapper iconName={mdiSwapHorizontal} {...props} />;
export const CancelIcon = props => <MaterialIconWrapper iconName={mdiCancel} {...props} />;
export const CogOutlineIcon = props => <MaterialIconWrapper iconName={mdiCogOutline} {...props} />;
export const CogIcon = props => <MaterialIconWrapper iconName={mdiCog} {...props} />;
export const ChatOutlineIcon = props => <MaterialIconWrapper iconName={mdiChatOutline} {...props} />;
export const MessageOutlineIcon = props => <MaterialIconWrapper iconName={mdiMessageOutline} {...props} />;
export const ArrowDownIcon = props => <MaterialIconWrapper iconName={mdiArrowDown} {...props} />;
export const LockOutlineIcon = props => <MaterialIconWrapper iconName={mdiLockOutline} {...props} />;
export const LockIcon = props => <MaterialIconWrapper iconName={mdiLock} {...props} />;
export const CastConnectedIcon = props => <MaterialIconWrapper iconName={mdiCastConnected} {...props} />;
export const SaleIcon = props => <MaterialIconWrapper iconName={mdiSale} {...props} />;
export const BookVariantIcon = props => <MaterialIconWrapper iconName={mdiBookVariant} {...props} />;
export const FileDocumentIcon = props => <MaterialIconWrapper iconName={mdiFileDocument} {...props} />;
export const MinusCircleOffIcon = props => <MaterialIconWrapper iconName={mdiMinusCircleOff} {...props} />;
export const StarIcon = props => <MaterialIconWrapper iconName={mdiStar} {...props} />;
export const BookOpenIcon = props => <MaterialIconWrapper iconName={mdiBookOpen} {...props} />;
export const SchoolIcon = props => <MaterialIconWrapper iconName={mdiSchool} {...props} />;
export const MoonWarningCrescentIcon = props => <MaterialIconWrapper iconName={mdiMoonWaningCrescent} {...props} />;
export const RadioBoxMarkedIcon = props => <MaterialIconWrapper iconName={mdiRadioboxMarked} {...props} />;
export const DeleteIcon = props => <MaterialIconWrapper iconName={mdiDelete} {...props} />;
export const FoodDrumstickIcon = props => <MaterialIconWrapper iconName={mdiFoodDrumstick} {...props} />;
export const WhiteBalanceSunnyIcon = props => <MaterialIconWrapper iconName={mdiWhiteBalanceSunny} {...props} />;
export const WeatherSunnyIcon = props => <MaterialIconWrapper iconName={mdiWeatherSunny} {...props} />;
export const DownloadIcon = props => <MaterialIconWrapper iconName={mdiDownload} {...props} />;
export const HelpCircleOutlineIcon = props => <MaterialIconWrapper iconName={mdiHelpCircleOutline} {...props} />;
export const AccountBadgeIcon = props => <MaterialIconWrapper iconName={mdiAccountBadge} {...props} />;
export const CookieIcon = props => <MaterialIconWrapper iconName={mdiCookie} {...props} />;
export const WifiOffIcon = props => <MaterialIconWrapper iconName={mdiWifiOff} {...props} />;
export const StarCircleOutlineIcon = props => <MaterialIconWrapper iconName={mdiStarCircleOutline} {...props} />;
export const FourStarIcon = props => <MaterialIconWrapper iconName={mdiStarFourPoints} {...props} />;
export const ChartBoxOutlineIcon = props => <MaterialIconWrapper iconName={mdiChartBoxOutline} {...props} />;
export const TicketConfirmationOutlineIcon = props => <MaterialIconWrapper iconName={mdiTicketConfirmationOutline} {...props} />;
export const LibraryOutlineIcon = props => <MaterialIconWrapper iconName={mdiLibraryOutline} {...props} />;
export const BankIcon = props => <MaterialIconWrapper iconName={mdiBank} {...props} />;
export const LayersOutlineIcon = props => <MaterialIconWrapper iconName={mdiLayersOutline} {...props} />;
export const CurrencyBtcIcon = props => <MaterialIconWrapper iconName={mdiCurrencyBtc} {...props} />;
export const FinanceIcon = props => <MaterialIconWrapper iconName={mdiFinance} {...props} />;
export const TextBoxIcon = props => <MaterialIconWrapper iconName={mdiTextBox} {...props} />;
export const PodiumGoldIcon = props => <MaterialIconWrapper iconName={mdiPodiumGold} {...props} />;
export const VoteOutlineIcon = props => <MaterialIconWrapper iconName={mdiVoteOutline} {...props} />;
export const FileSignIcon = props => <MaterialIconWrapper iconName={mdiFileSign} {...props} />;
export const SafeIcon = props => <MaterialIconWrapper iconName={mdiSafe} {...props} />;
export const CalculatorIcon = props => <MaterialIconWrapper iconName={mdiCalculator} {...props} />;
export const HandShakeOutlineIcon = props => <MaterialIconWrapper iconName={mdiHandshakeOutline} {...props} />;
export const GoldIcon = props => <MaterialIconWrapper iconName={mdiGold} {...props} />;
export const MicrophoneVariantIcon = props => <MaterialIconWrapper iconName={mdiMicrophoneVariant} {...props} />;
export const CommentQuoteOutlineIcon = props => <MaterialIconWrapper iconName={mdiCommentQuoteOutline} {...props} />;
export const SchoolOutlineIcon = props => <MaterialIconWrapper iconName={mdiSchoolOutline} {...props} />;
export const WindTurbineIcon = props => <MaterialIconWrapper iconName={mdiWindTurbine} {...props} />;
export const UmbrellaBeachOutlineIcon = props => <MaterialIconWrapper iconName={mdiUmbrellaBeachOutline} {...props} />;
export const ViewCarouselIcon = props => <MaterialIconWrapper iconName={mdiViewCarousel} {...props} />;
export const FireIcon = props => <MaterialIconWrapper iconName={mdiFire} {...props} />;
export const OpenInNewIcon = props => <MaterialIconWrapper iconName={mdiOpenInNew} {...props} />;
export const ListBoxOutlineIcon = props => <MaterialIconWrapper iconName={mdiListBoxOutline} {...props} />;
export const HomeAnalyticsIcon = props => <MaterialIconWrapper iconName={mdiHomeAnalytics} {...props} />;
export const CrystalBallIcon = props => <MaterialIconWrapper iconName={mdiCrystalBall} {...props} />;
export const ChartPieOutlineIcon = props => <MaterialIconWrapper iconName={mdiChartPieOutline} {...props} />;
export const BookSearchOutlineIcon = props => <MaterialIconWrapper iconName={mdiBookSearchOutline} {...props} />;
export const OrderAlphabeticalAscendingIcon = props => <MaterialIconWrapper iconName={mdiOrderAlphabeticalAscending} {...props} />;
export const CalendarTodayOutlineIcon = props => <MaterialIconWrapper iconName={mdiCalendarTodayOutline} {...props} />;
export const FileChartOutlineIcon = props => <MaterialIconWrapper iconName={mdiFileChartOutline} {...props} />;
export const StateMachineIcon = props => <MaterialIconWrapper iconName={mdiStateMachine} {...props} />;
export const FileCompareIcon = props => <MaterialIconWrapper iconName={mdiFileCompare} {...props} />;
export const DatabaseOutlineIcon = props => <MaterialIconWrapper iconName={mdiDatabaseOutline} {...props} />;
export const AbacusIcon = props => <MaterialIconWrapper iconName={mdiAbacus} {...props} />;
export const ChartTimelineVariantShimmerIcon = props => <MaterialIconWrapper iconName={mdiChartTimelineVariantShimmer} {...props} />;
export const BlurLinearIcon = props => <MaterialIconWrapper iconName={mdiBlurLinear} {...props} />;
export const VideoOutlineIcon = props => <MaterialIconWrapper iconName={mdiVideoOutline} {...props} />;
export const VideoCheckoutOutlineIcon = props => <MaterialIconWrapper iconName={mdiVideoCheckOutline} {...props} />;
export const PerecentBoxOutlineIcon = props => <MaterialIconWrapper iconName={mdiPercentBoxOutline} {...props} />;
export const CarBrakeHoldIcon = props => <MaterialIconWrapper iconName={mdiCarBrakeHold} {...props} />;
export const BullHornVariantOutlineIcon = props => <MaterialIconWrapper iconName={mdiBullhornVariantOutline} {...props} />;
export const ChartTimelineIcon = props => <MaterialIconWrapper iconName={mdiChartTimeline} {...props} />;
export const MessageAlertOutlineIcon = props => <MaterialIconWrapper iconName={mdiMessageAlertOutline} {...props} />;
export const AccountEditOutlineIcon = props => <MaterialIconWrapper iconName={mdiAccountEditOutline} {...props} />;
export const TelescopeIcon = props => <MaterialIconWrapper iconName={mdiTelescope} {...props} />;
export const CricketIcon = props => <MaterialIconWrapper iconName={mdiCricket} {...props} />;
export const ChevronLeftIcon = props => <MaterialIconWrapper iconName={mdiChevronLeft} {...props} />;
export const ChevronUpIcon = props => <MaterialIconWrapper iconName={mdiChevronUp} {...props} />;
export const ChartBubbleIcon = props => <MaterialIconWrapper iconName={mdiChartBubble} {...props} />;
export const TelevisionClassicIcon = props => <MaterialIconWrapper iconName={mdiTelevisionClassic} {...props} />;
export const LifeBuoyIcon = props => <MaterialIconWrapper iconName={mdiLifebuoy} {...props} />;
export const HeartIcon = props => <MaterialIconWrapper iconName={mdiHeart} {...props} />;
export const CellPhoneIcon = props => <MaterialIconWrapper iconName={mdiCellphone} {...props} />;
export const CellphoneDownloadIcon = props => <MaterialIconWrapper iconName={mdiCellphoneArrowDownVariant} {...props} />;
export const PiggyBankIcon = props => <MaterialIconWrapper iconName={mdiPiggyBank} {...props} />;
export const VoteIcon = props => <MaterialIconWrapper iconName={mdiVote} {...props} />;
