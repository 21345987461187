import React, { useEffect, useState } from 'react';
import { array, bool } from 'prop-types';
import take from 'lodash/take';
import MenuItem from '../../atoms/menu-item';
import SubMenuBar from '../../molecules/sub-menu-bar';
import './main-menu.m.css';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import Premium from '../../atoms/logo/premium';

const MainMenu = ({ showMenuBar }) => {
  const menu = useSelector((state) =>
    get(state, ['qt', 'data', 'navigationMenu', 'WebTopbarMenu'], [])
  );
  const menus = take(menu, 7);
  const [showMenuItems, setShowMenuItems] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMenuItems(true);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div styleName={`main-menu ${showMenuBar ? 'left-align' : ''}`}>
      {menus?.length > 0 && showMenuItems ? (
        <a styleName={`home-item ${showMenuBar ? 'mb-home-item' : ''}`} href="/?src=top_nav">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="15"
            viewBox="0 0 22.775 19.359"
          >
            <path
              fill="var(--regent-gray)"
              d="M11.11,22.359V15.526h4.555v6.833h5.694v-9.11h3.416L13.388,3,2,13.249H5.416v9.11Z"
              transform="translate(-2 -3)"
            />
          </svg>
        </a>
      ) : (
        <div stylename="home-item-skeleton" />
      )}
      {menus?.length > 0 && showMenuItems
        ? menus.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <MenuItem
                  hideIcon={!(item.children && item.children.length)}
                  href={`${item.completeUrl}?src=topnav` || '/'}
                  style={{ color: item?.data?.color ? item?.data?.color : '#2b2b2b' }}
                >
                  {item.completeUrl === '/premium' ? (
                    <Premium fontSize={'16px'} />
                  ) : item.completeUrl === '/budget' ? (
                    <img
                      src="/icons/budget-logo.svg"
                      style={{ marginBottom: '0px' }}
                      width="74px"
                      height="11px"
                    />
                  ) : (
                    item.title
                  )}
                </MenuItem>
                {item.children && item.children.length ? (
                  <SubMenuBar subMenu={item.children} />
                ) : (
                  ''
                )}
              </React.Fragment>
            );
          })
        : new Array(8).fill(null).map((element, i) => {
            return <span styleName="menu-item-skeleton" key={i + 1} />;
          })}
    </div>
  );
};

MainMenu.propTypes = {
  menu: array.isRequired,
  showHomeIcon: bool,
};

MainMenu.defaultProps = {
  menu: [],
};

export default MainMenu;
