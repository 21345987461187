import React from 'react';
import { arrayOf, object } from 'prop-types';

import DownloadIcon from '../../story/share/download.js';

import { formatPrice, formatDate } from '../../../helpers/utils';

import './my-invoices.m.css';

class MyInvoices extends React.Component {
  static showFile(blob) {
    const newBlob = new Blob([blob], { type: 'application/pdf' });
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = 'invoice.pdf';
    const event = new MouseEvent('click', { bubbles: false, cancelable: false, view: window });
    link.dispatchEvent(event);
  }

  constructor(props) {
    super(props);
    this.state = {
      viewInvoicesLimit: 5,
      increaseCounter: 5,
      invoices: this.getInvoices(props.subscriptions),
    };
  }

  componentDidUpdate(pProps) {
    if (pProps.subscriptions !== this.props.subscriptions) {
      this.setState({
        invoices: this.getInvoices(this.props.subscriptions),
      });
    }
  }

  getInvoices(subscriptions) {
    const invoices = [];
    subscriptions.forEach((subscription) => {
      if (!subscription?.recurring && subscription?.cancelled) {
        return;
      }

      if (subscription?.invoices?.length) {
        subscription?.invoices?.forEach((invoice) => {
          invoices.push({
            planName:
              (subscription?.dynamic_assets?.length && subscription?.dynamic_assets?.[0]?.title) ||
              subscription?.plan_name,
            isReward: subscription?.payment_type === 'reward',
            createdAt: formatDate(invoice.created_at, 'dd/MM/yyyy'),
            price: formatPrice(
              invoice.amount_cents / 100,
              invoice.amount_currency,
              'en-IN',
              0
            ).replace(/[,]/, ''),
            subscriptionId: subscription?.id,
            invoiceId: invoice?.id,
          });
        });
      } else {
        invoices.push({
          planName:
            (subscription?.dynamic_assets?.length && subscription?.dynamic_assets?.[0]?.title) ||
            subscription?.plan_name,
          createdAt: formatDate(subscription?.created_at, 'dd/MM/yyyy'),
          price: formatPrice(
            subscription?.payment_amount_cents / 100,
            subscription?.plan_amount_currency,
            'en-IN',
            0
          ).replace(/[,]/, ''),
          subscriptionId: subscription?.id,
          invoiceId: subscription?.invoices?.length && subscription?.invoices[0]?.id,
        });
      }
    });
    return invoices;
  }

  showMoreInvoices() {
    let counter = this.state.viewInvoicesLimit + this.state.increaseCounter;
    counter = counter < this.state.invoices.length ? counter : this.state.invoices.length;
    this.setState({
      viewInvoicesLimit: counter,
    });
  }

  downloadInvoice(subscriptionId, invoiceId) {
    global.AccessType &&
      global.AccessType.downloadInvoice(subscriptionId, invoiceId)
        .then((response) => {
          return response.blob();
        })
        .then(MyInvoices.showFile)
        .catch((error) => {
          console.error(error);
        });
  }

  render() {
    return (
      <div styleName="table-container my-invoices">
        <div styleName="table-header">
          <h2 styleName="table-headline">My Invoices</h2>
          <p styleName="table-sub-text">
            You have{' '}
            <strong>
              {this.state.invoices.length} {this.state.invoices.length > 1 ? 'Invoices' : 'Invoice'}
            </strong>
          </p>
        </div>
        <div>
          <div>
            <div styleName="table-row display">
              <div styleName="my-invoices-content">
                <div styleName="my-invoices-content-header title">Title</div>
                <div styleName="my-invoices-content-header date">Date of Purchase</div>
                <div styleName="my-invoices-content-header amount">Amount</div>
                <div styleName="my-invoices-content-header download">Download</div>
              </div>
            </div>
            {this.state.invoices.slice(0, this.state.viewInvoicesLimit).map((invoice) => (
              <div
                styleName="table-row"
                key={
                  invoice.invoiceId ? `inv-${invoice.invoiceId}` : `sub-${invoice.subscriptionId}`
                }
              >
                <div styleName="my-invoices-content">
                  <div styleName="my-invoices-content-body title">
                    <strong>{invoice.planName}</strong>
                    {invoice.isReward && (
                      <p styleName="my-invoices-content-body-referral">(Referral Reward)</p>
                    )}
                  </div>
                  <div styleName="my-invoices-content-body date">{invoice.createdAt}</div>
                  <div styleName="my-invoices-content-body amount">{invoice.price}</div>
                  <div styleName="my-invoices-content-body download">
                    <a
                      onClick={() =>
                        this.downloadInvoice(invoice.subscriptionId, invoice.invoiceId)
                      }
                    >
                      <DownloadIcon />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {this.state.invoices.length > this.state.viewInvoicesLimit && (
            <div styleName="load-more">
              <button styleName="load-more-btn" onClick={this.showMoreInvoices.bind(this)}>
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

MyInvoices.propTypes = {
  subscriptions: arrayOf(object).isRequired,
};

export default MyInvoices;
